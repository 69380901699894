/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import NewsDetailPage from '../components/NewsDetailPage';
import SEO from '../components/seo';

import '../styles/global.scss';

const BlogPage = ({ data, pageContext }) => {
  const { metadata, localizations } = data.strapiBlog;
  const global = data.strapiGlobal;
  return (
    <>
      <SEO seo={metadata} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <NewsDetailPage data={data.strapiBlog} />
      </Layout>
    </>
  );
};

export default BlogPage;

export const query = graphql`
  query blogsPage($id: String!, $locale: String!) {
  
    strapiGlobal(locale: { eq: $locale }) {
      navbar {
        companyLogo {
          url
        }
        companyLogoBlack {
          url
        }
        mainLinks {
          title
          url
        }
      }
      siteName
      siteDescription
      footer {
        footerContentTitle {
          title
        }
        company {
          title
          url
        }
        service {
          title
          url
        }
        contact {
          addressTitle
          address
          phoneTitle
          phoneNumber
          emailTitle
          email
        }
        legal {
          title
          url
        }
        copyright {
          titleOne
          titleTwo
        }
        footerBackgroundImage {
          url
        }
        footerBackgroundMobileImage {
          url
        }
        companyLogo {
          url
        }
      }
    }
    strapiBlog(id: {eq: $id}) {
      createdAt
      authorName
      authorImage {
        url
      }
      authorPosition
      construction
      contentFirst {
        data {
          contentFirst
        }
      }
      contentSecond {
        data {
          contentSecond
        }
      }
      date
      description
      id
      image {
        url
      }
      locale
      localizations {
        data {
          id
          attributes {
            locale
          }
        }
      }
      newsOrBlog
      categories
      slug
      subtitleFirst
      subtitleSecond
      timeRead
      title
      backToNewsAndBlogSlug
    }
  }
`;
